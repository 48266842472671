import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_ResponseView = _resolveComponent("ResponseView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_text_field, {
      modelValue: _ctx.password,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
      label: "パスワード"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_v_select, {
      modelValue: _ctx.engine,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.engine) = $event)),
      label: "言語モデル",
      items: _ctx.models,
      "item-title": "text"
    }, null, 8, ["modelValue", "items"]),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.enableFlavor)
      ? (_openBlock(), _createBlock(_component_v_text_field, {
          key: 0,
          modelValue: _ctx.flavor,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.flavor) = $event)),
          label: "味付け:出題の素材を何から取るかを誘導できます。有名な映画・漫画・ゲーム、特定の地域や時代設定、有名な人物などを指定するとわかりやすいでしょう。"
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.error_message)
      ? (_openBlock(), _createBlock(_component_v_alert, {
          key: 1,
          text: _ctx.error_message,
          type: "warning"
        }, null, 8, ["text"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ResponseView, { contentF: _ctx.contentF }, null, 8, ["contentF"])
  ], 64))
}