import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78737ec7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_MathAwareText = _resolveComponent("MathAwareText")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_divider, { thickness: "5" }),
    _createTextVNode(" (" + _toDisplayString(_ctx.index+1) + " 中間ステップ)", 1),
    _createVNode(_component_MathAwareText, {
      text: _ctx.question,
      class2: "sm"
    }, null, 8, ["text"]),
    (_ctx.raw)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.raw), 1))
      : _createCommentVNode("", true)
  ], 64))
}