<template>
    <div class="lg">{{ plain_question }}</div>
    <div class="lg"><span v-for='(ch, i) in choices.choice' v-bind:key="i">({{ i+1 }}) {{ch}}  </span></div>
    <div class="lt">正解：{{ answer }}</div>
    <div v-if="ex" class="sm">{{ ex }}</div>
    <p v-if="complexity_score">英文の複雑さのスコア: {{ complexity_score }}</p>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

function safeString(s:unknown):string {
  if (typeof s === 'string') return s
  try {
    return JSON.stringify(s)
  } catch(e) {
    return s as string
  }
}

export default defineComponent({
  name: 'EikenChoiceView',
  props: {
    qa: {type:Object, required:true},
  },
  mounted: function() {
    console.log("EikenChoice qa %o", this.qa)
  },
  data: function() {
    console.log("qa %o", this.qa)
    return {
        plain_question: this.qa.q,  // 問題文(テキスト)
        choices: this.qa.choices, // 選択肢(option)
        answer: safeString(this.qa.a), //解答
        ex: this.qa.ex, //解説(option)
        complexity_score: this.qa.complexity_score
    }
  }
})

</script>
<style scoped>
div.lg {
    font-size: 1.2em;
    font-weight: bold;
    text-align:left;
}
div.sm {
    font-size: 0.9em;
    text-align:left;
}
</style>