<template>
  <ProblemUI :subject-local="{category,subject:'english',mode:mode.value,grammer,word_count}">
    <template #default>
      <v-select v-model='category' label="カテゴリ" :items="category_list" item-title="text" />
      <v-combobox v-show="enable_grammer" v-model="grammer" label="出題する英文法の要素" :items="all_grammer_list" />
      <v-combobox v-show="enable_syntax_mode" v-model='mode' label="構文問題の種類" :items="syntax_modes" item-title="text" />
      <v-combobox v-show="enable_word_count" v-model='word_count' label="長文のサイズ(語)" :items="[200,300,400]" />
    </template>
  </ProblemUI>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import ProblemUI from '@/views/ProblemUI.vue';

const category_list = [
  {text:'選択肢問題・単語（英検３級水準）', value:'multiple_choice_word'},
  {text:'選択肢問題・フレーズ（英検３級水準）', value:'multiple_choice_phrase'},
  {text:'短文の英訳問題', value:'make_sentence'},

  {text:'構文 Who+be の疑問文(#18)', value:'syntax_who_be'},
  {text:'構文 Which+be の疑問文(#19)', value:'syntax_which_be'},
  {text:'構文 When+be の疑問文(#20)', value:'syntax_when_be'},
  {text:'構文 Where+be の疑問文(#21)', value:'syntax_where_be'},
  {text:'構文 Whose+be の疑問文(#22)', value:'syntax_whose_be'},
  {text:'構文 Why+be の疑問文(#23)', value:'syntax_why_be'},
  {text:'How many を使って数を尋ねる疑問文(#24)', value:'syntax_how_many'},
  {text:'How を使って手段を尋ねる疑問文(#25)', value:'syntax_how_verb'},
  {text:'How + 形容詞/副詞 を使って状態を尋ねる疑問文(#26)', value:'syntax_how_adj'},
  {text:'目的格とその変化(him/her/it/themなど)(#27)', value:'syntax_objective'},
  {text:'Let\'s ～ の提案文(#28)', value:'syntax_lets'},
  {text:'提案や指示をする命令文(#29)', value:'syntax_imperative'},
  {text:'禁止を表す命令文(#30)', value:'syntax_prohibited'},
  {text:'肯定文における三人称単数形の動詞の変化(#32)', value:'syntax_third_person_singular_affirmative'},
  {text:'疑問文における三人称単数形の動詞の変化(#33)', value:'syntax_third_person_singular_question'},
  {text:'否定文における三人称単数形の動詞の変化(#34)', value:'syntax_third_person_singular_negative'},

  {text:'長文読解（会話文）',value:'long_text'},
  {text:'長文読解（説明文）',value:'long_article'},
  //{text:'単語を並び替えて文法的に正しくなるようにする',value:'word_order'},
  //{text:'英文中の空欄にあてはまる語を選ぶ', value:'grammer_selection'}
]
const syntax_modes = [
  {text:'整序問題', value:'word_order'},
  {text:'整序問題(不使用単語1個)', value:'word_order_unused_1'}
]
const all_grammer_list = ['', '動詞', '形容詞', '副詞', '現在形', '現在完了形', '過去形', '過去完了形', '受動態', '比較級・最上級', '未来系', '疑問文', '関係代名詞', '不定詞']

export default defineComponent({
  name: 'EnglishView',
  props: {
    env: {type:Object, required:true}
  },
  data: () => {
    return {
        category:category_list[0].value,
        level:4,
        mode:syntax_modes[0],
        grammer:'',
        category_list:category_list,
        syntax_modes:syntax_modes,
        all_grammer_list:all_grammer_list,
        word_count:200,
        enable_grammer:true,
        enable_syntax_mode:false,
        enable_word_count:false,
    }
  },
  watch: {
    category: function(v) {
      this.enable_grammer = ['make_sentence', 'multiple_choice_word', 'multiple_choice_phrase'].indexOf(v)!=-1
      this.enable_syntax_mode = v.startsWith('syntax')
      this.enable_word_count = v.startsWith('long')
    }
  },
  components: {
    ProblemUI
  },
});
</script>
