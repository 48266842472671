import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import EnglishView from '../views/English.vue'
import EikenView from '../views/Eiken.vue'
import JuniorMathView from '../views/JuniorMath.vue'
import HighMathView from '../views/HighMath.vue'
import ScienceView from '../views/Science.vue'
import SocialView from '../views/Social.vue'
import KobunView from '../views/Kobun.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/english',
    name: 'english',
    component: EnglishView
  },
  {
    path: '/math',
    name: 'math',
    component: JuniorMathView
  },
  {
    path: '/high_math',
    name: 'high_math',
    component: HighMathView
  },
  {
    path: '/science',
    name: 'science',
    component: ScienceView
  },
  {
    path: '/social',
    name: 'social',
    component: SocialView
  },
  {
    path: '/eiken',
    name: 'eiken',
    component: EikenView
  },
  {
    path: '/kobun',
    name: 'kobun',
    component: KobunView
  },
  /*
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" * '../views/AboutView.vue')
  }
  */
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
