<template>
    <v-btn @click="generate" color="primary">実行</v-btn>
    <IntermediateQAView v-for="(item, index) in intermediate_question" :qa="item" :index="index" v-bind:key="index" />
    <QAView v-for="(item, index) in completed_question" :qa="item" :index="index" v-bind:key="index" :request="request" />
    <FragmentReceiver :header="header" :text="current_fragment" v-if="gpt_working" />
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import QAView from '@/components/QAView.vue';
import IntermediateQAView from '@/components/IntermediateQAView.vue';
import FragmentReceiver from '@/components/FragmentReceiver.vue';
import { sendQA, SmallQA } from '@/util/QASendAndReceive';

export default defineComponent({
  name: 'ResponseView',
  data: () => {
    return {
        header:'',
        gpt_working:false,
        request:null as object|null,
        current_fragment:'',
        intermediate_question:[] as SmallQA[],
        completed_question:[] as SmallQA[]
    }
  },
  props: {
    contentF: {type:Function, required:true} // リクエストを提供する関数
  },
  components: {
    IntermediateQAView,
    QAView,
    FragmentReceiver
  },
  methods: {
    generate: function() {
      const content = this.contentF()
      if(!content) return;
      this.request = content //渡したオブジェクト

      const wsf = ()=>new WebSocket((this.$root as any).config.websocket_host +'/') //何回か使うので関数にしておく
      const flavor = content.flavor
      this.header = ''
      this.current_fragment = ''
      this.intermediate_question = []
      this.completed_question = []
      this.gpt_working = true
      const fragment_func = (fragment:string)=>{this.current_fragment=fragment}
      const refine_qa = (index:number)=>{
        if(index<this.intermediate_question.length) {
          this.header = (index+1).toString() + '番目の問題を精製中...'
          const refine_requst = {subject:content.subject, category:'refinement',engine:content.engine, flavor:flavor,
           category_params:{q:this.intermediate_question[index]['q'], a:this.intermediate_question[index]['a'] }}
          sendQA(wsf(), refine_requst, fragment_func, (qa:SmallQA)=>{this.completed_question.push(qa)}, ()=>{refine_qa(index+1)})
        }
        else {
          this.header = ''
          this.gpt_working = false
        }
      }

      if(content.refinementMode=='math' && flavor.length>0) {
        //この場合、flavorの処置は２ステップ目でやる
        content.flavor = null
        sendQA(wsf(), content, fragment_func,(qa:SmallQA)=>{this.intermediate_question.push(qa)}, 
        ()=>{refine_qa(0)})
      }
      else { //直接completeQAを受ける
        sendQA(wsf(), content, fragment_func, (qa:SmallQA)=>{this.completed_question.push(qa)}, ()=>{this.gpt_working=false})
      }
    }
  }
});

</script>
