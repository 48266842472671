<template>
  <ProblemUI :subject-local="{category,subject:'eiken',grade}">
    <template #default>
      <v-select v-model='category' label="カテゴリ" :items="category_list" item-title="text" />
      <v-select v-model="grade" label="級指定" :items="grade_list" item-title="text" />
    </template>
  </ProblemUI>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import ProblemUI from '@/views/ProblemUI.vue';

const category_list = [
  {text:'単語・句４択問題', value:'choice_word'},
  {text:'会話文４択問題', value:'chat_word'},
  {text:'読解問題', value:'article'},
  {text:'メールの読解', value:'mail'}
]
const grade_list = [{text:'４級', value:'g4'},
{text:'３級', value:'g3'},
{text:'準２級', value:'g2p'},
{text:'２級', value:'g2'},
{text:'準１級', value:'g1p'},
{text:'１級', value:'g1'}]

export default defineComponent({
  name: 'EikenView',
  props: {
    env: {type:Object, required:true}
  },
  data: () => {
    return {
      category_list:category_list,
      grade_list:grade_list,
      category:category_list[0].value,
      grade:grade_list[0].value,
    }
  },
  watch: {
  },
  components: {
    ProblemUI
  },
});
</script>
