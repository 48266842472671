<template>
  <ProblemUI :subject-local-f="adjustmentF" :enableFlavor="false">
    <template #default>
      <v-select v-model='category' label="カテゴリ" :items="category_list" item-title="text" />
      <!-- ここはv-forで頑張るよりべた書きのほうが楽そう -->
      <v-select v-show="param0.options.list.length>0" v-model="param0.value" :label="param0.options.label" :items="param0.options.list" item-title="text" />
      <v-select v-show="param1.options.list.length>0" v-model="param1.value" :label="param1.options.label" :items="param1.options.list" item-title="text" />
      <v-select v-show="param2.options.list.length>0" v-model="param2.value" :label="param2.options.label" :items="param2.options.list" item-title="text" />
    </template>
  </ProblemUI>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import ProblemUI from '@/views/ProblemUI.vue';

type CategoryParamOption = {label:string, list:{text:string, value:string}[]}
const empty_options:CategoryParamOption = {label:'', list:[]}
type CategoryParam = {value:string, options:CategoryParamOption}

export default defineComponent({
  name: 'HighMathView',
  props: {
    env: {type:Object, required:true}
  },
  data: () => {
    return {
      category_list:[] as any[],
      category:undefined,
      category_options:{} as any,

        param0:{value:'', options:empty_options},
        param1:{value:'', options:empty_options},
        param2:{value:'', options:empty_options},
    }
  },
  mounted: function() {
    if(!this.env) return
    const math = this.env.subjects.high_math
    if(!math) return
    this.fill_options(math)
  },
  watch: {
    env: function(e:any) {
      console.log('watch env@math ')
      this.fill_options(e.subjects.high_math)
    },
    category: function (category:string) {
      const options = this.category_options[category]
      if(!options) console.log("invalid category %s", category)
      this.param0.options = options.length>0? options[0] : empty_options
      this.param0.value = ''
      this.param1.options = options.length>1? options[1] : empty_options
      this.param1.value = ''
      this.param2.options = options.length>2? options[2] : empty_options
      this.param2.value = ''
    }
  },
  methods: {
    fill_options: function(math:any) {
      this.category_list = math.map((e:any)=>({text:e.description, value:e.id}))
      console.log('list %o', this.category_list)
      this.category = this.category_list[0].value
      const t = {} as any
      const param_f = (e:any)=>({label:e.label, list:e.list})
      math.forEach((e:any)=>{t[e.id] = e.parameters.map(param_f)})
      this.category_options = t
    },
    adjustmentF: function(base_obj:any) {
      base_obj.category_id = this.category
      if(this.param0.options.label) 
        base_obj.category_params = {category_params: this.param0.value}
      console.log("adjustmentF %o", base_obj)
      return base_obj
    }
  },
  components: {
    ProblemUI
  }
});
</script>
