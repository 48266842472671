<template>
    <div class="lg">{{ plain_question }}</div>
    <div class="lg" v-if="selection">{{ selection }}</div>
    <div class="lt">正解：{{ answer }}</div>
    <div class="sm" v-if="ja">{{ ja }}</div>
    <div v-if="ex" class="sm">{{ ex }}</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

function safeString(s:unknown):string {
  if (typeof s === 'string') return s
  try {
    return JSON.stringify(s)
  } catch(e) {
    return s as string
  }
}

export default defineComponent({
  name: 'StandardEnglishQA',
  props: {
    qa: {type:Object, required:true},
  },
  data: function() {
    return {
        plain_question: this.qa.q,  // 問題文(テキスト)
        selection: this.qa.s, // 選択肢(option)
        answer: safeString(this.qa.a), //解答
        ja: this.qa.j, //日本語訳(option)
        ex: this.qa.ex, //解説(option)
    }
  }
})

</script>
<style scoped>
div.lg {
    font-size: 1.2em;
    font-weight: bold;
    text-align:left;
}
div.sm {
    font-size: 0.9em;
    text-align:left;
}
</style>