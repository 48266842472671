import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_ProblemUI = _resolveComponent("ProblemUI")!

  return (_openBlock(), _createBlock(_component_ProblemUI, {
    "subject-local-f": _ctx.adjustmentF,
    enableFlavor: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_select, {
        modelValue: _ctx.category,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.category) = $event)),
        label: "カテゴリ",
        items: _ctx.category_list,
        "item-title": "text"
      }, null, 8, ["modelValue", "items"]),
      _withDirectives(_createVNode(_component_v_select, {
        modelValue: _ctx.param0.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.param0.value) = $event)),
        label: _ctx.param0.options.label,
        items: _ctx.param0.options.list,
        "item-title": "text"
      }, null, 8, ["modelValue", "label", "items"]), [
        [_vShow, _ctx.param0.options.list.length>0]
      ]),
      _withDirectives(_createVNode(_component_v_select, {
        modelValue: _ctx.param1.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.param1.value) = $event)),
        label: _ctx.param1.options.label,
        items: _ctx.param1.options.list,
        "item-title": "text"
      }, null, 8, ["modelValue", "label", "items"]), [
        [_vShow, _ctx.param1.options.list.length>0]
      ]),
      _withDirectives(_createVNode(_component_v_select, {
        modelValue: _ctx.param2.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.param2.value) = $event)),
        label: _ctx.param2.options.label,
        items: _ctx.param2.options.list,
        "item-title": "text"
      }, null, 8, ["modelValue", "label", "items"]), [
        [_vShow, _ctx.param2.options.list.length>0]
      ])
    ]),
    _: 1
  }, 8, ["subject-local-f"]))
}