<template>
    <p>(日本文) {{ japanese }}</p>
    <p>(英訳)-最初は正しい英文になっています。意図的な誤りを加えてからレビューボタンを押してください。</p>
    <v-textarea v-model="english" rows="3" />
    <v-btn @click="review" color="primary">レビュー</v-btn>
    <p>{{ current_fragment }}</p>
    <div v-html="result_review" class="review"></div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { sendQA, SmallQA } from '@/util/QASendAndReceive';

export default defineComponent({
  name: 'CheckTranslation',
  data: () => {
    return {
        gpt_working:false,
        current_fragment:'',
        japanese:'',
        english:'',
        result_review:''
    }
  },
  props: {
    qa: {type:Object, required:true} // 素材文。日本語英語セットのJSON
  },
  mounted: function() {
    this.japanese = this.qa.j
    this.english = this.qa.e
  },
  components: {
  },
  methods: {
    review: function() {
      const wsf = ()=>new WebSocket((this.$root as any).config.websocket_host +'/') //何回か使うので関数にしておく
      this.current_fragment = ''
      this.gpt_working = true
      const fragment_func = (fragment:string)=>{this.current_fragment=fragment}

      const content = {subject:'english', category:'check_translation', engine:'gpt', flavor:'', category_params:{japanese:this.japanese, english:this.english}}

      //直接completeQAを受ける
      sendQA(wsf(), content, fragment_func, (r:SmallQA)=>{this.result_review = r.text!; this.current_fragment=''; console.log("r=%s", this.result_review)}, ()=>{this.gpt_working=false})
      
    }
  }
});

</script>
<style scoped>
div.review {
    font-size: 1.0em;
    text-align: left;
}
</style>