<template>
  <div>
    <p class="text">[{{ qa.title }}]</p>
    <p>本文</p>
    <p class="text">{{ qa.article }}</p>
    <p>設問</p>
    <div v-for="(item,index) in qa.q" class="question" v-bind:key="index">
      <hr/>
      <p>{{ item.head }}</p>
      <p><span v-for="(ch,i) in item.choices.choice" v-bind:key="i">({{ i+1 }}) {{ ch }} </span></p>
      <p>解答：{{ item.a }}</p>
      <p>解説：{{ item.ex }}</p>
      <p v-if='item.complexity_score'>英文の複雑さのスコア: {{ item.complexity_score }}</p>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EikenArticleView',
  props: {
    qa: {type:Object, required:true},
  },
  data: function() {
    return {
    }
  },
});
</script>
<style scoped>
div.question {
  text-align:left;
}
p.text {
  text-align:left;
}
</style>