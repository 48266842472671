import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f90e390"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lg" }
const _hoisted_2 = {
  key: 0,
  class: "lg"
}
const _hoisted_3 = { class: "lt" }
const _hoisted_4 = {
  key: 1,
  class: "sm"
}
const _hoisted_5 = {
  key: 2,
  class: "sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.plain_question), 1),
    (_ctx.selection)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.selection), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, "正解：" + _toDisplayString(_ctx.answer), 1),
    (_ctx.ja)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.ja), 1))
      : _createCommentVNode("", true),
    (_ctx.ex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.ex), 1))
      : _createCommentVNode("", true)
  ], 64))
}