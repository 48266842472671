<template>
    <v-divider thickness="5"></v-divider>
    ({{ index+1 }})
    <component :is="component_class" :qa="qa" v-if="component_class" />
    <!--
    <v-container>
      <v-row justify="center" v-if="qa.image_available">
        <v-col cols="auto">
          <span v-if="!image_url">画像生成中...</span>
          <img v-if="image_url" :src="image_url" width="512" height="512" alt="画像"/>
        </v-col>
      </v-row>
    </v-container>
    -->
  </template>
<script lang="ts">
import { defineComponent } from 'vue';
import DialogView from '@/components/DialogView.vue';
import ArticleView from '@/components/ArticleView.vue';
import CheckTranslation from '@/components/CheckTranslation.vue';
import StandardEnglishQA from '@/components/StandardEnglishQA.vue';
import StandardMathQA from '@/components/StandardMathQA.vue';
import EikenChoiceView from '@/components/EikenChoiceView.vue';
import EikenArticleView from '@/components/EikenArticleView.vue';
import TategakiQA from '@/components/TategakiQA.vue';

export default defineComponent({
  name: 'QAView',
  props: {
    qa: {type:Object, required:true},
    index: {type:Number, required:true},
    request: {type:Object, required:true} //生成時に渡したパラメータのコピー。これを見て中身のクラス分岐が使える
  },
  data: function() {
    //console.log("QAView data %o ", this.qa)
    return {
      raw: this.qa.raw, //生データ(エラー時のみ)
      
      //コンポーネントのクラス名, 段階的にこの手法でクラスの分岐を処理したい
      component_class: null as string|null, 
    }
  },
  mounted: function(){
    const is_object = typeof this.qa.q === 'object'
    const subject_id = this.request.category_id
    const subject = subject_id.substring(0, subject_id.indexOf('-'))
    if(subject=='eiken') {
      if(this.request.category == 'article' || this.request.category == 'mail')
        this.component_class = 'EikenArticleView'
      else
        this.component_class = 'EikenChoiceView'
    }
    else if(subject=='english') {
      if(is_object) {
        const is_dialog = is_object && this.qa.q.dialog
        this.component_class = is_dialog? 'DialogView' : 'ArticleView'
      }
      else if(this.request.category == 'make_sentence')
        this.component_class = 'CheckTranslation'
      else
        this.component_class = 'StandardEnglishQA'
    }
    else if(subject=='kobun') {
      this.component_class = 'TategakiQA'
    }
    else /*if(this.request.subject=='math')*/ { //理科・社会もこれでいいや
      this.component_class = 'StandardMathQA'
    }
  },
  components: {
    DialogView, ArticleView, CheckTranslation, StandardEnglishQA, StandardMathQA, EikenChoiceView, EikenArticleView, TategakiQA
  },
  methods: {
    api_root: function() {
      return (this.$root as any).config.api_host;
    },
  },
})

</script>
<style scoped>
div.lg {
    font-size: 1.2em;
    font-weight: bold;
    text-align:left;
}
div.sm {
    font-size: 0.9em;
    text-align:left;
}

</style>