<template>
    <div>{{ header }}</div>
    <div class="fragments">{{ text }}</div>
    <v-progress-circular indeterminate v-if="text.length>0"></v-progress-circular>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FragmentReceiver',
  props: {
    header: String,
    text: {type:String, required:true},
  },
  data: () => {
    return {
        completed:false,
    }
  },
  /* 古い、今はつかってない
  watch: {
    text: function (newText) {
        // questionは "q":"..." の部分
        const begin = newText.indexOf('"q":');
        if(begin==-1) {
            this.question = ''
        }
        else {
            const end = newText.indexOf('"', begin+7);
            if(end==-1) { // 'q'の終端に到達していない
                this.question = newText.substring(begin+5);
            }
            else {
                this.question = newText.substring(begin+5, end);
            }
        }
        //console.log("watch text %s, (%d,%d) %s", newText, begin,end, this.question)
    }
  */
});

</script>
<style scoped>
.fragments {
    text-align: left;
    margin: 10px;
    font-size: 0.8em
}
</style>