import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_ProblemUI = _resolveComponent("ProblemUI")!

  return (_openBlock(), _createBlock(_component_ProblemUI, {
    "subject-local": {category: _ctx.category,subject:'english',mode:_ctx.mode.value,grammer: _ctx.grammer,word_count: _ctx.word_count}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_select, {
        modelValue: _ctx.category,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.category) = $event)),
        label: "カテゴリ",
        items: _ctx.category_list,
        "item-title": "text"
      }, null, 8, ["modelValue", "items"]),
      _withDirectives(_createVNode(_component_v_combobox, {
        modelValue: _ctx.grammer,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.grammer) = $event)),
        label: "出題する英文法の要素",
        items: _ctx.all_grammer_list
      }, null, 8, ["modelValue", "items"]), [
        [_vShow, _ctx.enable_grammer]
      ]),
      _withDirectives(_createVNode(_component_v_combobox, {
        modelValue: _ctx.mode,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mode) = $event)),
        label: "構文問題の種類",
        items: _ctx.syntax_modes,
        "item-title": "text"
      }, null, 8, ["modelValue", "items"]), [
        [_vShow, _ctx.enable_syntax_mode]
      ]),
      _withDirectives(_createVNode(_component_v_combobox, {
        modelValue: _ctx.word_count,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.word_count) = $event)),
        label: "長文のサイズ(語)",
        items: [200,300,400]
      }, null, 8, ["modelValue"]), [
        [_vShow, _ctx.enable_word_count]
      ])
    ]),
    _: 1
  }, 8, ["subject-local"]))
}