<template>
    <div ref="container" :class="class2">
    </div>
</template>
<script lang="ts">
import { createApp, defineComponent, isReactive, toRaw } from 'vue';
// tslint:disable-next-line:no-var-requires
const VueMathjax = require('vue-mathjax-next');

function safeString(s:unknown):string {
  try {
    return JSON.stringify(s)
  } catch(e) {
    return s as string
  }
}

function splitMathPart(text:string, mathHandler:(t:string)=>void, normalTextHandler:(t:string)=>void) {
    function findMathPart(start:number) {
        const endText = text[start+1]=='(' ? '\\)' : '\\]'
        return text.indexOf(endText, start) + 2
    }

    //console.log("splitMathPart %o, isReactive %o", text, isReactive(text))
    //穴埋め問題の解答など、解答の文字列自体がJSONになっていることがあるので、正規表現にかける前にJSON.stringifyしておく
    const text2 = isReactive(text)? safeString(text) : text

    //const regex = /\\(\(|\[)[^\\]+\\(\)|\])/g
    const regex = /\\(\(|\[)/g
    let i = 0
    for(const m of text2.matchAll(regex)) {
        //console.log('m=%o', m)
        const p:number = m.index!
        if(i < p) 
          normalTextHandler(text2.substring(i, p))
        const j = findMathPart(p)
        mathHandler(text2.substring(p, j))
        i = j
    }
    if(i < text2.length) 
        normalTextHandler(text2.substring(i))
}

export default defineComponent({
  name: 'MathAwareText',
  props: {
    text: {type:String, required:true},
    class2: {type:String, required:true}
  },
  data: function() {
    return {
    }
  },
  mounted: function() {
    if(!this.text) return
    const container = this.$refs.container as HTMLElement;
    function appendElement(tag:string) {
      const el = document.createElement(tag)
      container.appendChild(el)
      return el
    }
    //console.log('mounted, text=%s', this.text)
    splitMathPart(this.text, (t:string) => {
      //数式は\(...\)だが、mathjaxは$...$を受け付ける  ブロックのときは$$...$$
      //ブロックは使わない、でいいだろう
      const c ='$' // t[1]=='(' ? '$' : '$$'
      t = c + t.substring(2, t.length-2) + c
      const mathjaxComponent = createApp(VueMathjax, { formula: t });
      //ブロック型の数式は未対応
      const mountEl = appendElement('span');
      mathjaxComponent.mount(mountEl);
    }, (t:string) => {
      appendElement('span').textContent = t
    })
  }
});
</script>
<style scoped>
div.lg {
    font-size: 1.2em;
    font-weight: bold;
    text-align: left;
}
</style>