<template>
    <v-divider thickness="5"></v-divider>
    ({{ index+1 }} 中間ステップ)<MathAwareText :text="question" class2="sm"/>
    <div class="sm" v-if="raw">{{ raw }}</div>
  </template>
<script lang="ts">
import { defineComponent } from 'vue';
import MathAwareText from '@/components/MathAwareText.vue';
// tslint:disable-next-line:no-var-requires
//const VueMathjax = require('vue-mathjax-next');

function safeString(s:unknown):string {
  if (typeof s === 'string') return s
  try {
    return JSON.stringify(s)
  } catch(e) {
    return s as string
  }
}

export default defineComponent({
  name: 'IntermediateQAView',
  props: {
    qa: {type:Object, required:true},
    index: {type:Number, required:true}
  },
  data: function() {
    return {
      question: this.qa.q,  // 問題文
      raw: this.qa.raw, //生データ(エラー時のみ)
    }
  },
  components: {
    MathAwareText
  },
  methods: {
  },
})

</script>
<style scoped>
div.lg {
    font-size: 1.2em;
    font-weight: bold;
    text-align:left;
}
div.sm {
    font-size: 0.9em;
    text-align:left;
}

</style>