<template>
  <v-text-field v-model='password' label="パスワード" />
  <v-select v-model='engine' label="言語モデル" :items="models" item-title="text" />
  <slot></slot>
  <v-text-field v-if="enableFlavor" v-model='flavor' label="味付け:出題の素材を何から取るかを誘導できます。有名な映画・漫画・ゲーム、特定の地域や時代設定、有名な人物などを指定するとわかりやすいでしょう。" />
  <v-alert :text="error_message" type="warning" v-if="error_message"></v-alert>
  <ResponseView :contentF="contentF" />
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import ResponseView from '@/components/ResponseView.vue';

const initial_password = process.env.NODE_ENV === 'development'? 'try0' : ''
const model_list = [{text:"Claude", value:"claude"}, {text:"ChatGPT-4o", value:"gpt"}, {text:"OpenAI-o1", value:"o1-preview"}]
const incorrect_password = '正しいパスワードを入れてください'
export default defineComponent({
name: 'ProblemUI',
props: {
  subjectLocal: {type:Object, required:false},
  subjectLocalF: {type:Function, required:false},
  enableFlavor: {type:Boolean, default:true}
},
data: () => {
  return {
      engine:'claude',
      flavor:'',
      enableCache:false,
      models:model_list,
      password:initial_password,
      error_message:initial_password.length>0? undefined : incorrect_password as (string | undefined),
  }
},
components: {
  ResponseView
},
watch: {
  password: function (newPassword) {
      this.error_message = (this.$root as any).config.password_list.indexOf(newPassword)!=-1? undefined : incorrect_password
  }
},
methods: {
    contentF: function() {
      if(this.error_message) return null
      const base_obj = {engine:this.engine, flavor:this.flavor, enable_cache:this.enableCache}
      let content;
      if(this.subjectLocalF)
        content = this.subjectLocalF(base_obj)
      else {
        content = Object.assign(base_obj, this.subjectLocal)
        //新しいcategory_idの仕様への対応をここでやる
        content.category_id = content.subject + '-' + content.category
      }
      return content
  }
}
});
</script>