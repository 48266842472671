import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_divider, { thickness: "5" }),
    _createTextVNode(" (" + _toDisplayString(_ctx.index+1) + ") ", 1),
    (_ctx.component_class)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component_class), {
          key: 0,
          qa: _ctx.qa
        }, null, 8, ["qa"]))
      : _createCommentVNode("", true)
  ], 64))
}