<template>
  <div class="tategaki">
    <div class="lg">(問題)<br/>{{ plain_question }}</div>
    <div class="sm">(解答)<br/>{{ answer }}</div>
    <div v-if="ex" class="sm">(解説)<br/>{{ ex }}</div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

function safeString(s:unknown):string {
  if (typeof s === 'string') return s
  try {
    return JSON.stringify(s)
  } catch(e) {
    return s as string
  }
}

export default defineComponent({
    name: 'TategakiQA',
    props: {
      qa: {type:Object, required:true},
    },
    data: function() {
      return {
          remark: this.qa.remark,  // 備考
          plain_question: this.qa.q,  // 問題文(テキスト)
          answer: safeString(this.qa.a), //解答
          ex: this.qa.ex, //解説
          comment: this.qa.comment, //もしあれば
          situation_html:this.qa.situation
      }
    },
  })
  
  </script>
  <style scoped>
  div.tategaki {
      writing-mode: vertical-rl;
      text-align: center;
      text-underline-position: right;
      width: 100%;
      display: flex;
      flex-wrap: wrap; /* 高さを超えたら次行に移動 */
      height: 300px;
  }
  div.lg {
      font-size: 1.2em;
      font-weight: bold;
      text-align:left;
  }
  div.sm {
      font-size: 0.9em;
      text-align:left;
  }
  
  </style>