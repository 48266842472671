import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_ProblemUI = _resolveComponent("ProblemUI")!

  return (_openBlock(), _createBlock(_component_ProblemUI, {
    enableFlavor: false,
    "subject-local": {category: _ctx.category,image_flavor: _ctx.image_flavor,level: _ctx.level,subject:'science'}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_select, {
        modelValue: _ctx.category,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.category) = $event)),
        label: "カテゴリ",
        items: _ctx.category_list,
        "item-title": "text"
      }, null, 8, ["modelValue", "items"]),
      _createVNode(_component_v_select, {
        modelValue: _ctx.level,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.level) = $event)),
        label: "レベル",
        items: _ctx.level_list,
        "item-title": "text"
      }, null, 8, ["modelValue", "items"])
    ]),
    _: 1
  }, 8, ["subject-local"]))
}