import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// Vuetify
//import 'vuetify/styles'
import 'vuetify/dist/vuetify.min.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives';

// tslint:disable-next-line:no-var-requires
const VueMathjax = require('vue-mathjax-next');

const vuetify = createVuetify({
  components,
  directives,
})

// Desmosスクリプトを動的に読み込む
const script = document.createElement('script');
script.src = 'https://www.desmos.com/api/v1.9/calculator.js?apiKey=dcb31709b452b1cf9dc26972add0fda6';
document.head.appendChild(script);

createApp(App).use(vuetify).use(router).use(VueMathjax).mount('#app')
