import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_app_bar_nav_icon, {
                variant: "text",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.drawer = !_ctx.drawer), ["stop"]))
              }),
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("小中学生向け問題の自動生成デモ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn_toggle, {
                modelValue: _ctx.main_subject,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.main_subject) = $event)),
                color: "primary",
                variant: "flat"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, { value: "english" }, {
                    default: _withCtx(() => [
                      _createTextVNode("英語")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, { value: "math" }, {
                    default: _withCtx(() => [
                      _createTextVNode("中学数学")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, { value: "high_math" }, {
                    default: _withCtx(() => [
                      _createTextVNode("高校数学")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, { value: "science" }, {
                    default: _withCtx(() => [
                      _createTextVNode("理科")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, { value: "social" }, {
                    default: _withCtx(() => [
                      _createTextVNode("社会")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, { value: "eiken" }, {
                    default: _withCtx(() => [
                      _createTextVNode("英検")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, { value: "kobun" }, {
                    default: _withCtx(() => [
                      _createTextVNode("古文")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_v_spacer)
            ]),
            _: 1
          }),
          _createVNode(_component_v_main, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_view, { env: _ctx.env }, null, 8, ["env"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}