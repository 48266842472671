<template>
  <ProblemUI :enableFlavor='false' :subject-local="{category,image_flavor,level,subject:'social'}">
    <template #default>
      <v-select v-model='category' label="カテゴリ" :items="category_list" item-title="text" />
      <v-select v-model='level' label="レベル" :items="level_list" item-title="text" />
      <!--
      <v-select v-model='image_flavor' label="画像の味付け" :items="image_flavor_list" item-title="text" />
      -->
    </template>
  </ProblemUI>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import ProblemUI from '@/views/ProblemUI.vue';

//const title_list = ['織田信長と豊臣秀吉','尊王攘夷運動と江戸幕府の滅亡','北海道の地理','アジアの気候と農業']
//const category_list = title_list.map(t=>{return {text:'穴埋め問題/'+t,value:'blank_words_'+t}})
const category_list = [{text:'明治時代に関する選択肢問題', value:'choices_meiji'}]
const level_list = [{text:'易しい(明らかに誤りの選択肢を入れる)', value:0}, {text:'普通(ある程度紛らわしい選択肢を入れる)', value:1}, {text:'難しい(異なる地域・時代の知識を複合する)', value:2}]

const image_flavor_list = [
{text:'指定なし',value:null},
{text:'水彩画',value:'水彩画'},
{text:'漫画',value:'漫画'},
{text:'単色イラスト',value:'単色イラスト'},
{text:'幾何的な平面図形',value:'幾何的な平面図形'},
{text:'進撃の巨人',value:'進撃の巨人'},
]
export default defineComponent({
  name: 'SocialView',
  props: {
      env: {type:Object, required:true}
    },
  data: () => {
    return {
        category:category_list[0].value,
        level:1,
        image_flavor:null,
        category_list:category_list,
        level_list:level_list,
        image_flavor_list:image_flavor_list,
    }
  },
  components: {
    ProblemUI
  },
  methods: {
  }
});
</script>
