<template>
  <div class="home">
    <ul>
      <li>上から科目を選択してください。</li>
      <li>これはデモなので、性能を確かめるのが目的です。この方向で強化して、徐々に実用的なものにしていきます。</li>
      <li>質問・感想・要望は歓迎です。</li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  components: {
  },
});
</script>
