<template>
  <ProblemUI :subject-local-f="adjustmentF">
    <template #default>
      <v-select v-model='category' label="カテゴリ" :items="category_list" item-title="text" />
      <!-- ここはv-forで頑張るよりべた書きのほうが楽そう -->
      <v-select v-show="param0.options.list.length>0" v-model="param0.value" :label="param0.options.label" :items="param0.options.list" item-title="text" />
      <v-select v-show="param1.options.list.length>0" v-model="param1.value" :label="param1.options.label" :items="param1.options.list" item-title="text" />
      <v-select v-show="param2.options.list.length>0" v-model="param2.value" :label="param2.options.label" :items="param2.options.list" item-title="text" />
    </template>
  </ProblemUI>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import ProblemUI from '@/views/ProblemUI.vue';

const category_list_old = [
{text:'中１・正負の数・文章題(#3)', value:'negative_integer_story_problem'},
{text:'中１・正負の数・基準値と表から平均を求める(#20)', value:'relative_value_table_problem'},
{text:'中１・正負の数・計算問題(#7-#15)', value:'j1_negative_number_calc'},
{text:'中１・正負の数・素因数分解の利用', value:'j1_prime_factor'},
{text:'中１・文字と式・文字を使うときのルール1', value:'j1_term_rule1'},
{text:'中１・文字と式・一次式の計算問題(#3)', value:'j1_literal_expr_expansion_mul'},
{text:'中１・文字と式・文字と数の積の表記(#33-#35)', value:'j1_linear_expr_calc'},
{text:'中１・文字と式・分配法則・結合法則の利用', value:'j1_associative_law_calc'},
{text:'中１・文字と式・文字１個を使って量を表現する(#21)', value:'literal_expr_story_problem_1of2'},
{text:'中１・文字と式・文字２個を使って量を表現する(#25)', value:'literal_expr_story_problem_2of4'},
{text:'中１・文字と式・割合を使った表現(#27)', value:'literal_expr_percent_story_problem_1of2'},
{text:'中１・文字と式・速さと道のり(#28,#29)', value:'speed_and_time'},
{text:'中１・文字と式・文章から等式を作る(#37)', value:'literal_expr_1of3'},
{text:'中１・方程式・一般的な文章題(#46)', value:'linear_equation_heyawari_problem'},
{text:'中１・方程式・足りない/余る(#47)', value:'linear_equation_wariate_problem'},
{text:'中１・方程式・速さの文章題(#48)', value:'linear_equation_chasing_problem'},
{text:'中１・比例と反比例・比例の文章題(#63)', value:'proportional_story'},
{text:'中１・比例と反比例・反比例の文章題(#64)', value:'inverse_proportional_story'},
{text:'中２・連立方程式・個数の系統(#115)', value:'j2_linear_eq_2of8'},
{text:'中２・連立方程式・時間の系統(#116)', value:'j2_linear_eq_2of6_time'},
{text:'中２・連立方程式・割引率の系統(#117)', value:'j2_linear_eq_2of6_discount'},
{text:'中２・確率・サイコロ(#164)', value:'j2_probability_dice_story'},
{text:'中２・確率・１個選択する(#165)', value:'j2_probability_pickup_story'},
{text:'中２・１次関数・点と傾きから方程式とグラフを得る(#125)', value:'j2_linear_function_and_graph'},
{text:'中３・２次方程式・解と係数の関係・１つの解から方程式を決定', value:'j3_kai_keisu_1'},
{text:'中３・２次方程式・解と係数の関係・２つの解から方程式を決定', value:'j3_kai_keisu_2'},
{text:'中３・２次方程式・解と係数の関係・整数解と自然数解による制約', value:'j3_kai_keisu_3'},
{text:'中３・平方根・文字式を使う計算・x+y,xyが簡単になることの利用', value:'j3_sqrt_calc_1'},
{text:'中３・関数y=ax^2・放物線と直線の交点', value:'j3_para_and_line'},
{text:'中３・関数y=ax^2・xとyの変域', value:'j3_para_range'},
{text:'中３・関数y=ax^2・変化の割合', value:'j3_para_xy_ratio'},
]

type CategoryParamOption = {label:string, list:{text:string, value:string}[]}
const empty_options:CategoryParamOption = {label:'', list:[]}
const category_options_old:{[category:string]: CategoryParamOption[]} = {
  negative_integer_story_problem:[],
  relative_value_table_problem:[],
  literal_expr_story_problem_1of2:[],
  literal_expr_story_problem_2of4:[],
  literal_expr_percent_story_problem_1of2:[],
  speed_and_time:[],
  literal_expr_1of3:[],
  linear_equation_heyawari_problem:[],
  linear_equation_wariate_problem:[],
  linear_equation_chasing_problem:[],
  proportional_story:[],
  inverse_proportional_story:[],
  j1_literal_expr_expansion_mul:[],
  j1_prime_factor:[],
  j1_negative_number_calc:[{label:'タイプ', list:
    [{text:'整数の加算減算', value:'int,addsub'},
    {text:'整数の乗算除算', value:'int,muldiv'},
    {text:'整数の四則演算', value:'int,arithmetic'},
    {text:'有理数の加算減算', value:'fraq,addsub'},
    {text:'有理数の乗算除算', value:'fraq,muldiv'},
    {text:'有理数の四則演算', value:'fraq,arithmetic'},
    ]}],
  j1_linear_expr_calc:[{label:'タイプ', list:
    [{text:'整数係数の加算減算', value:'int,addsub'},
    {text:'整数係数の乗算除算', value:'int,muldiv'},
    {text:'有理係数の加算減算', value:'fraq,addsub'},
    {text:'有理係数の乗算除算', value:'fraq,muldiv'},
    ]}],
  j1_associative_law_calc:[{label:'タイプ', list:
    [{text:'整数の範囲', value:'int'},
    {text:'有理数の範囲', value:'fraq'},
    ]}],
  j2_linear_eq_2of8:[],
  j2_linear_eq_2of6_time:[],
  j2_linear_eq_2of6_discount:[],
  j2_probability_dice_story:[],
  j2_probability_pickup_story:[],
  j2_linear_function_and_graph:[],

  j3_kai_keisu_1:[],
  j3_kai_keisu_2:[],
  j3_kai_keisu_3:[],
  j3_sqrt_calc_1:[],
  j3_para_and_line:[],
  j3_para_range:[],
  j3_para_xy_ratio:[],
}
type CategoryParam = {value:string, options:CategoryParamOption}

export default defineComponent({
  name: 'JuniorMathView',
  props: {
    env: {type:Object, required:true}
  },
  data: () => {
    return {
      category_list:[] as any[],
      category:undefined,
      category_options:{} as any,

        param0:{value:'', options:empty_options},
        param1:{value:'', options:empty_options},
        param2:{value:'', options:empty_options},
    }
  },
  mounted: function() {
    // { "id": "negative_integer_story_problem", "description": "中１・正負の数・文章題(#3)",    "parameters": []}
    if(!this.env) return
    const math = this.env.subjects.math
    if(!math) return
    this.fill_options(math)
  },
  watch: {
    env: function(e:any) {
      console.log('watch env@math ')
      this.fill_options(e.subjects.math)
    },
    category: function (category:string) {
      const options = this.category_options[category]
      if(!options) console.log("invalid category %s", category)
      this.param0.options = options.length>0? options[0] : empty_options
      this.param0.value = ''
      this.param1.options = options.length>1? options[1] : empty_options
      this.param1.value = ''
      this.param2.options = options.length>2? options[2] : empty_options
      this.param2.value = ''
    }
  },
  methods: {
    fill_options: function(math:any) {
      this.category_list = math.map((e:any)=>({text:e.description, value:e.id}))
      this.category = this.category_list[0].value
      const t = {} as any
      const param_f = (e:any)=>({label:e.label, list:e.list})
      math.forEach((e:any)=>{t[e.id] = e.parameters.map(param_f)})
      this.category_options = t
    },
    adjustmentF: function(base_obj:any) {
      base_obj.category_id = this.category
      if(this.param0.options.label) 
        base_obj.category_params = {category_params: this.param0.value}
      console.log("adjustmentF %o", base_obj)
      return base_obj
    }
  },
  components: {
    ProblemUI
  }
});
</script>
