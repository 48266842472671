<template>
  <div>
    <p>{{ situation }}</p>
    <p>本文</p>
    <div v-for="(item,index) in dialogList" v-bind:key="index">
      <p class="speaker">{{ item.speaker }}</p>
      <p class="text">{{ item.text }}</p>
    </div>
    <p>設問</p>
    <p v-for="(item,index) in questionList" class="question" v-bind:key="index">{{ item }}</p>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DialogView',
  props: {
    qa: {type:Object, required:true},
  },
  data: function() {
    return {
      questionList:[] as string[],
      dialogList:[] as {speaker:string, text:string}[],
      situation: ''
    }
  },
  mounted: function() {
    const q = this.qa.q
    this.questionList = this.formatQuestion(q.question)
    this.dialogList = q.dialog
    this.situation = q['situation']
  },
  methods: {
    formatQuestion: function(q:any) {
      if(typeof q === 'string')
        return [q]
      else if(Array.isArray(q))
        return q
      else if(typeof q === 'object')
        return [q]
      else
        return [q]
    }
  }
});
</script>
<style scoped>
p.question {
  text-align:left;
}
p.speaker {
  text-align:left;
  font-weight:bold;
  color: gray;
}
p.text {
  text-align:left;
}
</style>