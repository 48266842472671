<template>
  <div>
    <p>本文</p>
    <p class="text">{{ qa.question.text }}</p>
    <p>設問</p>
    <p v-for="(item,index) in questionList" class="question" v-bind:key="index">{{ item }}</p>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ArticleView',
  props: {
    qa: {type:Object, required:true},
  },
  data: function() {
    return {
      questionList:[] as string[],
    }
  },
  mounted: function() {
    this.questionList = this.formatQuestion(this.qa.question.question)
  },
  methods: {
    formatQuestion: function(q:any) {
      if(typeof q === 'string')
        return [q]
      else if(Array.isArray(q))
        return q
      else if(typeof q === 'object')
        return [q]
      else
        return [q]
    }
  }
});
</script>
<style scoped>
p.question {
  text-align:left;
}
p.text {
  text-align:left;
}
</style>