import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d32eff0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fragments" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, _toDisplayString(_ctx.header), 1),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.text), 1),
    (_ctx.text.length>0)
      ? (_openBlock(), _createBlock(_component_v_progress_circular, {
          key: 0,
          indeterminate: ""
        }))
      : _createCommentVNode("", true)
  ], 64))
}