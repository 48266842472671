
//数式のまわりでパースエラーになる
const tryParseJSON = (t:string)=>{
    try {
      return JSON.parse(t)
    }
    catch(e) {
      return {raw:t, reason:e}
    }
  }
const tryParseXML = (t:string)=>{
    try {
      return new DOMParser().parseFromString(t, 'text/xml')
    }
    catch(e) {
      return {raw:t, reason:e}
    }
  }

export type SmallQA = {
    q:string|object|undefined,
    a:string|undefined,
    e:string|undefined,
    choices:string[]|undefined,
    ja:string|undefined,
    text:string|undefined,
    remark:string|undefined,
    comment:string|undefined,
    situation:string|undefined //HTML形式
}
function XMLtoSmallQA(elem:Element, level:number):SmallQA {
  const obj:any = {};
  function append(key:string, value:any) {
    if(obj[key]) {
      if(Array.isArray(obj[key])) {
        obj[key].push(value)
      }
      else {
        obj[key] = [obj[key], value]
      }
    }
    else {
      obj[key] = value
    }
  }

  // 子要素をループしてオブジェクトに追加
  for (const child of elem.children) {
    const tag = child.tagName
    if (child.children.length > 0) {
      append(tag, XMLtoSmallQA(child, level+1))
    }
    else
      append(tag, child.textContent)
  }
  
  //console.log("level %d, obj %o", level, obj)
  return obj as SmallQA;
}
  
// リクエストを送信し、断片テキストを受信するコールバックと、１問分のJSON受信時のコールバック、完了時のコールバックを受け取る関数
export function sendQA(ws:WebSocket, request:object, fragment_callback:((content:string)=>void), qa_callback:((qa:SmallQA)=>void), complete_callback:()=>void) {
      //受信しながら状態の変わるオブジェクト
      const state = {
          current_fragment: '',
      }

      function json_mode(fragment:string) {
        const begin = fragment.indexOf('{')
        const end = fragment.lastIndexOf('}')
        const elem = tryParseJSON(fragment.substring(begin, end+1));
        console.log('parsed element %o', elem);
        
        if(elem.json_body) {
          fragment_callback('')
          if(elem.intermediates.length >= 1) {
            for(let i=0; i<elem.intermediates.length; i++){
              const intermediate = tryParseJSON(elem.intermediates[i])
              for(let j=0; j<intermediate.length; j++){
                console.log('intermediate %o', intermediate[j])
                qa_callback(intermediate[j])
              }
            }
          }

          for(let i=0; i<elem.json_body.length; i++){
            qa_callback(elem.json_body[i]) // startInfoは小問ごとで共通なのでassignの方向に制限がある
          }
        }
      }
      function xml_mode(fragment:string) {
        const begin = fragment.indexOf('<')
        const end = fragment.lastIndexOf('>')
        const src = fragment.substring(begin, end+1)
        const doc = tryParseXML(src);
        let situation:string|null
        //situationがあるときは最初のQA内の属性として入れてしまう。本当は、SmallQAの型をちゃんと変更すべき
        function iterate(elem:Element) {
          if(elem.tagName=='situation') {
            situation = elem.innerHTML
          }
          else if(elem.tagName=='item') {
            const qa = XMLtoSmallQA(elem, 0)
            if(situation!=null) {
              qa.situation = situation
              situation = null
            }
            qa_callback(qa)
          }
          else {
            for(let i=0; i<elem.children.length; i++){
              iterate(elem.children[i])
            }
          }
        }
        fragment_callback('')
        iterate((doc as Document).documentElement)
      }
    
      ws.onopen = () => {
        console.log('sending %o', request)
        ws.send(JSON.stringify(request))
      }
      ws.onmessage = (e) => {
        //console.log('onmessage %o', e.data)
        const fragment = e.data
        //あまりスマートとはいえないが、サーバ側で処理終了したと判断できる文字列を見つけたらパースに入る
        if(fragment.indexOf('{"message": "end"')>=0) { // バックエンドではPythonのdictをそのまま文字列化している。
          json_mode(fragment)
        }
        else if(fragment.indexOf('<response')>=0 || fragment.indexOf('<item')>=0) { //<response>,<response-list>,<item-list>のどちらか
          xml_mode(fragment)
        }
        else {
          //通常はだらだらと断片を垂れ流してよい
          state.current_fragment += fragment
          fragment_callback(state.current_fragment)
        }
    }
}