import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_IntermediateQAView = _resolveComponent("IntermediateQAView")!
  const _component_QAView = _resolveComponent("QAView")!
  const _component_FragmentReceiver = _resolveComponent("FragmentReceiver")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_btn, {
      onClick: _ctx.generate,
      color: "primary"
    }, {
      default: _withCtx(() => [
        _createTextVNode("実行")
      ]),
      _: 1
    }, 8, ["onClick"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.intermediate_question, (item, index) => {
      return (_openBlock(), _createBlock(_component_IntermediateQAView, {
        qa: item,
        index: index,
        key: index
      }, null, 8, ["qa", "index"]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.completed_question, (item, index) => {
      return (_openBlock(), _createBlock(_component_QAView, {
        qa: item,
        index: index,
        key: index,
        request: _ctx.request
      }, null, 8, ["qa", "index", "request"]))
    }), 128)),
    (_ctx.gpt_working)
      ? (_openBlock(), _createBlock(_component_FragmentReceiver, {
          key: 0,
          header: _ctx.header,
          text: _ctx.current_fragment
        }, null, 8, ["header", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}