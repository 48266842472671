import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b4e5c8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "sm" }
const _hoisted_3 = {
  key: 1,
  class: "sm"
}
const _hoisted_4 = {
  key: 2,
  class: "sm"
}
const _hoisted_5 = {
  key: 3,
  class: "sm"
}
const _hoisted_6 = {
  key: 4,
  ref: "graphContainer",
  style: {"width":"300px","height":"400px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MathAwareText = _resolveComponent("MathAwareText")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.situation_html)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          innerHTML: _ctx.situation_html
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createVNode(_component_MathAwareText, {
      text: _ctx.plain_question,
      class2: "lg"
    }, null, 8, ["text"]),
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode("(解答)"),
      _createVNode(_component_MathAwareText, {
        text: _ctx.answer,
        class2: "sm"
      }, null, 8, ["text"])
    ]),
    (_ctx.ex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createTextVNode("(解説) "),
          _createVNode(_component_MathAwareText, {
            text: _ctx.ex,
            class2: "sm"
          }, null, 8, ["text"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.comment)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createTextVNode("(校正) "),
          (_ctx.comment)
            ? (_openBlock(), _createBlock(_component_MathAwareText, {
                key: 0,
                text: _ctx.comment,
                class2: "sm"
              }, null, 8, ["text"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.remark)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, "(注)" + _toDisplayString(_ctx.remark), 1))
      : _createCommentVNode("", true),
    (_ctx.desmos)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, null, 512))
      : _createCommentVNode("", true)
  ], 64))
}