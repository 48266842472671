<template>
    <div v-if="situation_html" v-html='situation_html'></div>
    <MathAwareText :text="plain_question" class2="lg"/>
    <div class="sm">(解答)<MathAwareText :text="answer" class2="sm"/></div>
    <div v-if="ex" class="sm">(解説) <MathAwareText  :text="ex" class2="sm"/></div>
    <div v-if='comment' class='sm'>(校正) <MathAwareText v-if="comment" :text="comment" class2="sm"/></div>
    <div v-if="remark" class="sm">(注){{ remark }}</div>
    <div v-if='desmos' ref="graphContainer" style="width: 300px; height: 400px;"></div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import MathAwareText from '@/components/MathAwareText.vue';

declare global {
  interface Window {
    Desmos: any;
  }
}
function safeString(s:unknown):string {
  if (typeof s === 'string') return s
  try {
    return JSON.stringify(s)
  } catch(e) {
    return s as string
  }
}

export default defineComponent({
    name: 'StandardMathQA',
    props: {
      qa: {type:Object, required:true},
    },
    data: function() {
      return {
          remark: this.qa.remark,  // 備考
          plain_question: this.qa.q,  // 問題文(テキスト)
          answer: safeString(this.qa.a), //解答
          ex: this.qa.ex, //解説
          comment: this.qa.comment, //もしあれば
          desmos:this.qa.desmos, //グラフ使用時のみ
          situation_html:this.qa.situation
      }
    },
    mounted: function(){
      if(this.qa.desmos)
        this.loadDesmos(this.qa.desmos)
    },
    components: {
      MathAwareText
    },
    methods: {
      loadDesmos: function(desmos:any) {
        const elt = this.$refs.graphContainer as HTMLElement
        const calculator = window.Desmos.GraphingCalculator(elt, {expressions: false, settingsMenu:true, zoomButtons:true})
        console.log('load desmos %o', desmos)
        for(const expr in desmos)
          calculator.setExpression(desmos[expr])
      }
    },
  })
  
  </script>
  <style scoped>
  div.lg {
      font-size: 1.2em;
      font-weight: bold;
      text-align:left;
  }
  div.sm {
      font-size: 0.9em;
      text-align:left;
  }
  
  </style>