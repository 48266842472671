import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b2f546a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lg" }
const _hoisted_2 = { class: "lg" }
const _hoisted_3 = { class: "lt" }
const _hoisted_4 = {
  key: 0,
  class: "sm"
}
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.plain_question), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.choices.choice, (ch, i) => {
        return (_openBlock(), _createElementBlock("span", { key: i }, "(" + _toDisplayString(i+1) + ") " + _toDisplayString(ch), 1))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, "正解：" + _toDisplayString(_ctx.answer), 1),
    (_ctx.ex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.ex), 1))
      : _createCommentVNode("", true),
    (_ctx.complexity_score)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, "英文の複雑さのスコア: " + _toDisplayString(_ctx.complexity_score), 1))
      : _createCommentVNode("", true)
  ], 64))
}