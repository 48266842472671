<template>
  <ProblemUI :subject-local-f="adjustmentF" :enableFlavor="false">
    <template #default>
      <v-select v-model='category' label="カテゴリ" :items="category_list" item-title="text" />
    </template>
  </ProblemUI>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import ProblemUI from '@/views/ProblemUI.vue';

type CategoryParamOption = {label:string, list:{text:string, value:string}[]}
const empty_options:CategoryParamOption = {label:'', list:[]}

export default defineComponent({
  name: 'KobunView',
  props: {
    env: {type:Object, required:true}
  },
  data: () => {
    return {
      category_list:[] as any[],
      category:undefined,
      category_options:{} as any,
    }
  },
  mounted: function() {
    if(!this.env) return
    const kobun = this.env.subjects.kobun
    console.log('watch env@kobun %o', this.env.subjects)
    if(!kobun) return
    this.fill_options(kobun)
  },
  watch: {
    env: function(e:any) {
      this.fill_options(e.subjects.kobun)
    },
    category: function (category:string) {
      const options = this.category_options[category]
      if(!options) console.log("invalid category %s", category)
    }
  },
  methods: {
    fill_options: function(kobun:any) {
      this.category_list = kobun.map((e:any)=>({text:e.description, value:e.id}))
      console.log('list %o', this.category_list)
      this.category = this.category_list[0].value
    },
    adjustmentF: function(base_obj:any) {
      base_obj.category_id = this.category
      console.log("adjustmentF %o", base_obj)
      return base_obj
    }
  },
  components: {
    ProblemUI
  }
});
</script>
