<template>
  <v-app>
    <v-layout>
      <v-app-bar color="primary">
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>小中学生向け問題の自動生成デモ</v-toolbar-title>
        <v-btn-toggle
          v-model="main_subject"
          color="primary"
          variant="flat"
        >
          <v-btn value="english">英語</v-btn>
          <v-btn value="math">中学数学</v-btn>
          <v-btn value="high_math">高校数学</v-btn>
          <v-btn value="science">理科</v-btn>
          <v-btn value="social">社会</v-btn>
          <v-btn value="eiken">英検</v-btn>
          <v-btn value="kobun">古文</v-btn>
        </v-btn-toggle>
        <v-spacer></v-spacer>
      </v-app-bar>
      <!--
      <v-navigation-drawer v-model="drawer">
        <v-list-item title="問題の自動生成" subtitle="ChatGPT"></v-list-item>
        <v-divider></v-divider>
        <v-list-item title="List Item 1"></v-list-item>
        <v-radio-group>
          <v-radio label="Radio One" value="one"></v-radio>
          <v-radio label="Radio Two" value="two"></v-radio>
          <v-radio label="Radio Three" value="three"></v-radio>
        </v-radio-group>
      </v-navigation-drawer>
      -->
      <v-main>
        <router-view :env="env"/>
      </v-main>
    </v-layout>
  </v-app>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';
const prod = process.env.NODE_ENV !== 'development'
const host = prod? 'gpt.lagarto.co.jp' : 'localhost'
const http_scheme = prod? 'https':'http'
const websocket_scheme = prod? 'wss':'ws'

export default defineComponent({
  name: 'App',
  components: {
  },
  data: () => {
    return {
      drawer: false,
      main_subject: '',
      env:null,
      config: {
        api_host: http_scheme+'://'+host+':3001',
        websocket_host:websocket_scheme+'://'+host+':3001',
        password_list: ['try0', '2024']
      }
    }
  },
  mounted: function() {
    axios.get(this.config.api_host + '/env').then((response) => {
      this.env = response.data
      console.log("env %o", this.env)
    })
  },
  watch: {
    main_subject: function (subj) {
      if(subj)
        this.$router.push('/'+subj);
    },
    $route: function(to) {
      if(this.main_subject!=to.name) this.main_subject = to.name
    }
  }
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

table {
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid black;
}
</style>
